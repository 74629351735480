import { h } from "preact";
import { signOut } from "firebase/auth";
import { route } from "preact-router";

const Header = ({ auth, currentUser, currentUserPerms, pinkUids }) => {
	const logout = () => {
		signOut(auth);
	};

	const pinkHeader = pinkUids.includes(currentUser?.uid)
		? "background: #ffb7c5"
		: false;

	const pinkHeaderLink = pinkUids.includes(currentUser?.uid)
		? "color: white"
		: false;

	const pinkHeaderLink2 = pinkUids.includes(currentUser?.uid)
		? "margin-left: 10px; color: white"
		: "margin-left: 10px";

	return (
		<header class="site-header">
			<nav
				class="navbar navbar-expand-md navbar-dark bg-steel fixed-top"
				style={pinkHeader}
			>
				<div class="container">
					<a
						class="navbar-brand mr-4"
						onClick={() => route("/add-contact-log", true)}
					>
						VPBR
					</a>
					<button
						class="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarToggle"
						aria-controls="navbarToggle"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarToggle">
						<div class="navbar-nav mr-auto">
							{currentUserPerms.includes("addContactLog") && (
								<a
									class="nav-item nav-link"
									style={pinkHeaderLink}
									onClick={() => route("/add-contact-log", true)}
								>
									Add Contact Log
								</a>
							)}
							{currentUserPerms.includes("contactLogReport") && (
								<a
									class="nav-item nav-link"
									style={pinkHeaderLink2}
									onClick={() => route("/contact-log-report", true)}
								>
									Contact Log Report
								</a>
							)}
							{currentUserPerms.includes("filesToScan") && (
								<a
									class="nav-item nav-link"
									style={pinkHeaderLink2}
									onClick={() => route("/files-to-scan", true)}
								>
									Files to Scan
								</a>
							)}
						</div>
						<div class="navbar-nav">
							{currentUserPerms.includes("admin") && (
								<a
									class="nav-item nav-link"
									style={pinkHeaderLink}
									onClick={() => route("/admin", true)}
								>
									Admin
								</a>
							)}
							{!currentUser && (
								<a
									class="nav-item nav-link"
									style={pinkHeaderLink}
									onClick={() => route("/login", true)}
								>
									Login
								</a>
							)}
							{currentUser && (
								<a
									class="nav-item nav-link"
									style={pinkHeaderLink}
									onClick={logout}
								>
									Logout
								</a>
							)}
						</div>
					</div>
				</div>
			</nav>
		</header>
	);
};

export default Header;
